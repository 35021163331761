
































































































import {
  computed,
  defineComponent,
  onMounted,
  ref, useRoute, watch,
} from '@nuxtjs/composition-api';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import LazyHydrate from 'vue-lazy-hydration';

import { SwitchAndSaveBanner } from '~/components/SwitchAndSave';
import { useStructuredData } from '~/composables';
import { getHomeBestsellers } from '~/composables/useHome';
import { HOME_BLOCK_IDS } from '~/constants';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import ManufacturerPreloaded from '~/modules/catalog/category/components/views/ManufacturerPreloaded/ManufacturerPreloaded.vue';
import SearchWidget from '~/modules/catalog/category/components/views/SearchWidget.vue';
import { useCmsBlocksStore } from '~/stores/cmsBlocks';

import HomeBestsellers from '../components/Home/Bestsellers/Bestsellers.vue';
import HomeAdvantages from '../components/Home/HomeAdvantages/HomeAdvantages.vue';
import HomeTestimonials from '../components/Home/HomeTestimonials.vue';
import HomePopularPrinters from '../components/Home/PopularPrinters.vue';
import PromoAbout from '../components/Home/PromoAbout.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    LazyHydrate,
    SwitchAndSaveBanner,
    PromoAbout,
    SearchWidget,
    HomeAdvantages,
    HomeBestsellers,
    HomePopularPrinters,
    HomeTestimonials,
    ManufacturerPreloaded,
  },
  setup() {
    const { addTags } = useCache();
    const { collectOrganizationJsonLd } = useStructuredData();
    const { load, getCmsBlockByIdentifier } = useCmsBlocksStore();
    const route = useRoute();

    const jsonLd = computed(() => collectOrganizationJsonLd());
    const bestsellers = ref({ ink: [], toner: [] });

    watch(route, () => {
      if (!getCmsBlockByIdentifier(HOME_BLOCK_IDS[0])) {
        load(HOME_BLOCK_IDS, true);
      }
    }, { immediate: true });

    onMounted(async () => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
      bestsellers.value = await getHomeBestsellers();
    });

    return {
      jsonLd,
      bestsellers,
    };
  },
  head() {
    return getMetaInfo(this.page);
  },
});
